/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui"

export default ({ children }) => (
    <h3 sx={{ 
      fontSize: [2,3],
      fontWeight: "bold", 
      fontFamily: "heading", 
      lineHeight: 1, 
    }}>
      {children}
    </h3>
)