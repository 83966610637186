/** @jsx jsx */
import { Link } from "gatsby"
import { jsx, Themed, Heading, BaseStyles, Link as A } from "theme-ui"
// import * as React from "react";
import Layout from "../components/layout"
import Contain from "../components/contain"
import Stack from "../components/stack"
import blueprint from "../images/blueprint-5.png"
import SEO from "../components/seo"
import PageTitle from "../components/pageTitle"
import Headline from "../components/editorial-headline"
import SubHeadline from "../components/editorial-subheadline"
import ThicLine from "../components/editorial-thicline"

// markup
const IndexPage = () => {
  return (
    <Layout>

      <Contain mw={"col10"}>
        {/* Editorial Headlines */}
        <SEO title="The Manifesto" />
        <PageTitle name="The Manifesto" />
        <div
          sx={{
            mt: 4,
            mb: [4,4,5],
          }}
        > 
          <SubHeadline>Everyone is Remote</SubHeadline>
          <Headline>Only ModSquad is Cubeless&trade;</Headline>
        <ThicLine />
        </div>
        
        {/* Preamble */}
        <div sx={{ 
          maxWidth: "col10", 
          pl: [0,4]
          }}>
          <p sx={{ 
            fontSize: [2,3], 
            fontStyle: "italic" 
           }}>
            Why is ModSquad &mdash; a CX services company &mdash; revealing a security platform? Well, listen up!
          </p>
        </div>
        
        {/* Two Col Layout Wrapper */}
        <div
          sx={{
            mt: 4,
            display: "flex",
            flexDirection: "row",
            gap: 3,
          }}
        >
          {/* Col 1 - Manifesto Copy */}
          <div sx={{
            maxWidth: 524, 
            flex: 1,
            pl: [0,4],  
            }}>
            <BaseStyles>
              <Stack gap={4}>
                <Stack gap={3}>
                  <h3>Our competitors used to knock the remote workforce</h3>

                  <p>Before the pandemic, they sang a different tune.</p>

                  <p>They said remote didn’t work &mdash; that it wasn’t secure.</p>
                  <p>They told you the only way to have secure CX services was to stick agents in cubicles in soulless call centers on the outskirts of town.</p>
                  <p>Now, the same companies who tried to talk you out of remote are singing its praises.</p>
                </Stack>

                <Stack gap={3}>
                  <h3>We’ve always been certain about remote</h3>

                  <p>For us, remote wasn’t a reaction. It isn’t our survival plan for “uncertain times”. It’s not the temporary menu.</p>

                  <p>It’s not a recent revelation that there’s a better way.</p>
                  <p>We envisioned a world without cubicles from day one. That’s why we’ve been perfecting, delivering, and championing our distributed CX services approach since we started back in 2007.</p>
                  <p>So when it came time to name the platform that secures our remote workforce, the choice was obvious.</p>
                </Stack>

                <Stack gap={3}>
                  <h3>We call it Cubeless</h3>

                  <p>Cubeless is how we secure our remote team and protect your customers. It secures everything from our remote Mods to your customers and everything in between: the devices, the connections, all of it, to the cloud and back.</p>
                </Stack>

                <Stack gap={3}>
                  <h3>Cubeless is more than tech</h3>

                  <p>Sending everyone home and buying some tech doesn’t make you a remote work expert &mdash; it doesn’t make you Cubeless.</p>

                  <p>Cubeless isn’t just a piece of software or a suite of applications. Cubeless is also the policies, workflows, best practices, and hard-won knowledge that has been honed in the remote forge for the past 14 years.</p>

                  <p>Cubeless is tried and true, tested and vetted.</p>

                  <p>And Cubeless is <b>patent pending</b>.</p>

                  <p>You can’t buy that. You can’t license it. You can’t hack it together in a year.</p>

                  <p>But you can get it from ModSquad.</p>
                </Stack>
                <Stack gap={3}>
                  <h3>Trust us</h3>

                  <p>
                    <strong>CX without cubicles</strong> was our founding insight. It’s our life’s work. It’s the way we’ve been delivering the best outsourced support, moderation, and engagement for our clients for well over a decade.
                  </p>

                  <p>We’re not jumping on the bandwagon. We like to do our own thing. We prefer to steer rather than go along for a ride. We prefer scooters.</p>
                </Stack>
      
                </Stack>
                </BaseStyles>
                <div sx={{
                    mt: 4
                }}>
                <Stack gap={4}>
                  <Themed.p>So, hop on.</Themed.p>

                  <Heading
                    sx={{
                      // fontFamily: 'heading',
                      // fontWeight: 'bold',
                      fontSize: [4, 5],
                      // lineHeight: 0.9,
                    }}
                  >
                    Go Cubeless with ModSquad.
                  </Heading>
                </Stack>
                </div>

            

            <div
              sx={{
                mt: 5,
              }}
            >
                <p
                  sx={{
                    fontSize: 1,
                    textTransform: "uppercase",
                    fontStyle: "italic",
                    fontFamily: "sans",
                    fontWeight: "900",
                  }}
                >
                  <Link
                    sx={{
                      p: [0,0,0,2],
                      color: "text",
                      textDecoration: "none",
                    }}
                    to={"/"}
                  >
                    Learn more about the Cubeless Platform &#x27F6;
                  </Link>
                </p>
            </div>
          </div>
          
          {/* Col 2 - Blueprint imagery */}
          <div sx={{ 
              maxWidth: 524, 
              display: ['none', 'none', 'block']
           }}>
            <img  sx={{opacity: '50%', }}
                  src={blueprint}
                  alt="Cubeless blueprint" />
          </div>

        </div> 
        {/* End of 2 col layout */}
      
      </Contain>
    </Layout>
  )
}

export default IndexPage
